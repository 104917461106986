/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IThreeDViewerConfig } from '../../models/IThreeDViewerConfig';
import { IThreeDViewerState } from '../../models/IThreeDViewerState';
import { Toolbox } from './Measurement/ToolBox/Toolbox';
import { getAuthorizationData, refreshToken, getTokenIfValidAndNotExpired, fetchWithAuthorisationHeader } from "../../services/AuthenticationService";
import { IThreeDModel } from '../../models/IThreeDModel';
import { IGeneralSnackbarConfig } from '../../models/IGeneralSnackbarConfig';
import { GeneralSnackbar } from '../SnackBar/GeneralSnackbar/GeneralSnackbar';
import AssetMarker from './AssetMarker/AssetMarker';
import axios from 'axios';

interface IProps {
    config: IThreeDViewerConfig | null;
    getViewerStateFlag?: number;
    viewerState: IThreeDViewerState | null;
    onGetViewerState?: Function;
}
export function ThreeDViewer(props: IProps) {

    const [viewer, setViewer] = useState<any | null>(null);
    const [fullModelUrl, setFullModelUrl] = useState<string | null>(null);
    const [toolboxInitialiseFlag, setToolboxInitialiseFlag] = useState<number>(0);
    const [model, setModel] = useState<any>(null);
    const [generalSnackbarConfig, setGeneralSnackbarConfig] = useState<IGeneralSnackbarConfig | null>(null);
    const [selectionMode, setSelectionMode] = useState<boolean>(false);
    const [lastSegmentId, setLastSegmentId] = useState<number>(0);
    const [heightOffset, setHeightOffset] = useState<number>(0.0);
    const [ionAccess, setIonAccess] = useState<boolean | null>(null);

    const toggleSelectionMode = (isOn: boolean) => {
        setSelectionMode(isOn);
    }

    // FYI This Cesium is a global object loaded via js file in index.html as there were compilation issues with Cesium from Npm.
    let Cesium = (window as any).Cesium;

    const CESIUM_TOKEN = process.env.REACT_APP_CESIUM_TOKEN;
    Cesium.Ion.defaultAccessToken = CESIUM_TOKEN;

    async function getModelsForSegment(viewer: any, segmentId: number): Promise<any> {

        let url = process.env.REACT_APP_VAA_API_URL + "threedmodel/models/" + segmentId;

        var response = await fetchWithAuthorisationHeader(url);
        return response.data as IThreeDModel[];
    }

    async function loadModel(viewer: any, modelUrl: string, heightOffset: number, popupState: IThreeDViewerState | null) {
        try {

            var authData = getAuthorizationData();

            // Load the model into Cesium.  Cesium will call our api to get files as needed
            // For authorisation it passes the access_token query string parameter

            // If the token expires during an api call we refresh it in the retryCallback function

            const resource = new Cesium.Resource({
                url: modelUrl,
                queryParameters: {
                    'access_token': authData.token
                },
                retryCallback: retryCallback,
                retryAttempts: 5
            })

            let tileset = await Cesium.Cesium3DTileset.fromUrl(resource, {
                shadows: Cesium.ShadowMode.DISABLED,
                preloadWhenHidden: true,
                maximumScreenSpaceError: 1, // Default 16
                //debugShowBoundingVolume: true,
                //debugShowContentBoundingVolume: true,
            });

            var tilesetEventListener = function () {
                let terrainHeight: number = 0;
                let autoHeightOffset: number = 0;
                let averageHeightOffset: number = 0;

                console.log('All tile was loaded.');

                let modelCentre = Cesium.Cartographic.fromCartesian(tileset.boundingSphere.center);
                //let modelCentre = Cesium.Cartographic.fromDegrees(-111.6815456, 40.2655038);
                //terrainProvider	TerrainProvider	The terrain provider from which to query heights.
                //level	Number	The terrain level - of - detail from which to query terrain heights.
                //positions
                var positions = [
                    modelCentre,
                ];

                //  console.log('The supplied height is a height above the reference ellipsoid (such as Ellipsoid.WGS84) rather than an altitude above mean sea level. ');
                //  console.log('In other words, it will not necessarily be 0.0 if sampled in the ocean.');
                Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, positions)
                    .then(function (samples: any) {
                        console.log('sampleTerrainMostDetailed Height in meters is: ' + positions[0].height);
                        terrainHeight = positions[0].height;

                        // Get Tileset height at point
                        //let tileHeight: number = tileset.getHeight(modelCentre, viewer.scene);
                        let tileHeight: number = getModelHeight(viewer, tileset, modelCentre, 5.0, 10, 2); // 2 - return lowest value
                        console.log('Tile.getHeight  ' + tileHeight);

                        autoHeightOffset = (terrainHeight - tileHeight);
                        console.log('Calcualted Height Offset ' + autoHeightOffset);

                        setModelHeightOffset(tileset, autoHeightOffset);

                        // Remove event so it doesn't continue to fire everytime you zoom in to the model
                        tileset.allTilesLoaded.removeEventListener(tilesetEventListener);

                    });

            } 

            if (heightOffset === 0 ) {
                tileset.allTilesLoaded.addEventListener(tilesetEventListener);
            }
            else {
                console.log('Using height offset from DB ' + heightOffset);
                setModelHeightOffset(tileset, heightOffset);
            }

            
            setModel(viewer.scene.primitives.add(tileset));

            var flyToPositionEventListener = function () {
                if (popupState != null) {
                    flyToPosition(popupState.cameraPosition, popupState.cameraHeading, popupState.cameraPitch, viewer);
                    tileset.allTilesLoaded.removeEventListener(flyToPositionEventListener); // Ensure event only fires once. 
                }
            }

            if (popupState != null) {
                // The component has been popped out so restore the view the user was looking at
                tileset.allTilesLoaded.addEventListener(flyToPositionEventListener);
            }

            viewer.scene.globe.depthTestAgainstTerrain = true;
            viewer.zoomTo(
                tileset,
                new Cesium.HeadingPitchRange(
                    0.0,
                    -0.5,
                    tileset.boundingSphere.radius * 2.0
                )
            );
        } catch (error: any) {
            setGeneralSnackbarConfig({ messageType: 'error', message: 'Failed to load the model. Please try again or contact Support if the problem persists.', verticalAnchorOrigin: 'bottom', autoHideDuration: 5000 });
        }
    }

    const retryCallback: any = async (resource: any, error: any) => {

        if (error?.statusCode === 401) {

            // Auth issue due to token expiry.  We *might* need to refresh the token
            // As we are potentially loading multiple tiles at the same time we can get a race condtion where they are all trying to refresh the token at almost the same time
            // So first check if we actually do have a valid token from a recent refresh

            var token = getTokenIfValidAndNotExpired();

            if (token == null) {
                // We dont have a valid token so do a refresh
                token = await refreshToken();
            }

            if (token != undefined && resource != undefined) {
                resource.setQueryParameters({ 'access_token': token })
                return true;
            }
        }
        return false
    }

    const resetGeneralSnackbarIonBased = () =>{
        //prioratise ion error against any other error.
        if(ionAccess === false){
            setGeneralSnackbarConfig({ messageType: 'error', message: 'No base map shown due to missing key. Please notify AIMS support.', verticalAnchorOrigin: 'bottom', autoHideDuration: 5000 });
        }
        else{
            setGeneralSnackbarConfig(null);
        }
    }

    useEffect(() => {
      const getProfile = async () => {
        await axios
          .get('https://api.cesium.com/v1/me', {
            headers: { Authorization: 'Bearer ' + CESIUM_TOKEN }
          })
          .then(
            (result) => {
              if (result.status === 200) {
                setIonAccess(true);
              }
            },
            (error) => {
              if (error.response.status === 401) {
                setIonAccess(false);
              }
              else{
                console.log(error.data);
              }
            }
          );
      };

      getProfile();
    }, []);
    
    useEffect(() => {

        async function tryLoadModel(config: IThreeDViewerConfig, viewerState: IThreeDViewerState | null, theViewer: any) {

            // We only care about popupState at the time the component gets popped out
            let popupState = viewerState != null && viewerState.segmentId === config.segmentId ? viewerState : null;

            let fileUrl = popupState != null ? popupState.fullModelUrl : null;
            let heightOffset = popupState != null ? popupState.heightOffset : 0.0;

            if (!popupState) {
                // Does the segment have any models?
                let models = await getModelsForSegment(theViewer, config.segmentId);

                if (models && models.length > 0) {
                    // Load the first model which is the newest
                    // We pass the model url (usually to tileset.json) to Cesium and then it will load tile files from that same path
                    fileUrl = process.env.REACT_APP_VAA_API_URL + "threedmodel/modelfile" + (!models[0].path.startsWith('/') ? '/' : '') + models[0].path;
                    heightOffset = models[0].heightOffset;
                }
            }

            setFullModelUrl(fileUrl);
            setHeightOffset(heightOffset);

            if (fileUrl) {
                loadModel(theViewer, fileUrl, heightOffset, popupState);
            }
            else if (popupState) {
                // Component has been popped out and segment does not have any models

                // Fly to the the position that the user was looking at
                flyToPosition(popupState.cameraPosition, popupState.cameraHeading, popupState.cameraPitch, theViewer);
            }
            else {
                // Segment does not have any models
                let height = 30;
                flyToLocation(config.location.latitude, config.location.longitude, height, theViewer);
            }
        }

        let theViewer = viewer;
        if (theViewer === null) {
            theViewer = new Cesium.Viewer("cesiumContainer", {
                infoBox: false,
                selectionIndicator: false,
                shadows: false,
                shouldAnimate: true,
                animation: false,
                fullscreenButton: false,
                geocoder: false,
                timeline: false,
                homeButton: false,
                sceneModePicker: false,
                scene3DOnly: true, //saves memory
                creditContainer: "credits",
                creditViewport: "credits",
                terrain: Cesium.Terrain.fromWorldTerrain({
                    requestWaterMask: true,
                    requestVertexNormals: true,
                }),
            });

            setViewer(theViewer);
        }

        if (props.config != null && props.config.segmentId !== lastSegmentId) {
            resetGeneralSnackbarIonBased();

            setLastSegmentId(props.config.segmentId);

            // Remove any old models first
            theViewer.scene.primitives.remove(model);
            setToolboxInitialiseFlag(toolboxInitialiseFlag + 1);

            tryLoadModel(props.config, props.viewerState, theViewer);
        }

        return () => {
            // this now gets called when the component unmounts
        };


    }, [props.config, viewer]);

    useEffect(() => {
        resetGeneralSnackbarIonBased();

        if (viewer && props.onGetViewerState && props.getViewerStateFlag && props.getViewerStateFlag > 0 && props.config) {

            // Return the current viewer state so it can be popped out

            props.onGetViewerState({
                segmentId: props.config.segmentId,
                cameraPitch: viewer.camera.pitch,
                cameraHeading: viewer.camera.heading,
                cameraPosition: viewer.camera.position,
                fullModelUrl: fullModelUrl,
                heightOffset: heightOffset
            });
        }

    }, [props.getViewerStateFlag, viewer, fullModelUrl]);

    /**
     * Return the lowest of the point heights or the average of the point height.
     * @param viewer The Cesium viewer
     * @param tileset The Cesium tileset
     * @param center The tileset centre
     * @param radius The radiun to generate random point
     * @param count The number of iterations
     * @param type 1 = average, 2 = lowest
     * @returns The lowest height or the average height
     */
    function getModelHeight(viewer:any, tileset:any, center: any, radius: number, count:number, type:number) {
        var sumOfHeight: number = 0;
        var iterations: number = 0;
        var lowestHeight: number = 0;
        for (var i = 0; i < count; i++) {
            var randomPoint = generateRandomPoint({ lat: center.latitude / Math.PI * 180, lng: center.longitude / Math.PI * 180 }, radius);
            var pointHeight = tileset.getHeight(Cesium.Cartographic.fromDegrees(randomPoint.lng, randomPoint.lat), viewer.scene);
            if (!isNaN(pointHeight)) {
                iterations++;
                sumOfHeight = sumOfHeight + pointHeight;

                if (lowestHeight === 0 || pointHeight < lowestHeight) lowestHeight = pointHeight;
            }
        }

        if (type === 1) return sumOfHeight / iterations;
        return lowestHeight;
    }

    /**
    * Generates number of random geolocation points given a center and a radius.
    * Reference URL: http://goo.gl/KWcPE.
    * @param  {Object} center A JS object with lat and lng attributes.
    * @param  {number} radius Radius in meters.
    * @return {Object} The generated random points as JS object with lat and lng attributes.
    */
    function generateRandomPoint(center: any, radius: number) {
        var x0 = center.lng;
        var y0 = center.lat;
        // Convert Radius from meters to degrees.
        var rd = radius / 111300;

        var u = Math.random();
        var v = Math.random();

        var w = rd * Math.sqrt(u);
        var t = 2 * Math.PI * v;
        var x = w * Math.cos(t);
        var y = w * Math.sin(t);

        var xp = x / Math.cos(y0);

        // Resulting point.
        return { 'lat': y + y0, 'lng': xp + x0 };
    }
    
    function flyToLocation(latitude: number, longitude: number, height: number, viewer: any) {

        viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
                longitude,
                latitude,
                height
            ),
            orientation: {
                heading: Cesium.Math.toRadians(0.0),
                pitch: Cesium.Math.toRadians(-15.0),
            }
        });
    }

    function flyToPosition(cameraPosition: any, cameraHeading: any, cameraPitch: any, viewer: any) {

        viewer.camera.flyTo({
            destination: cameraPosition,
            orientation: {
                heading: cameraHeading,
                pitch: cameraPitch,
            }
        });
    }

    // Offset the 3d tileset height
    function setModelHeightOffset(tileset: any, heightOffset: number) {
        const cartographic = Cesium.Cartographic.fromCartesian(
            tileset.boundingSphere.center
        );
        const surface = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            0.0
        );
        const offset = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            heightOffset
        );
        const translation = Cesium.Cartesian3.subtract(
            offset,
            surface,
            new Cesium.Cartesian3()
        );
        tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
    }

    return (<div style={{ height: '100%', width: '100%' }}>
        <div id="cesiumContainer" style={{ height: '100%', width: '100%' }}>
            {viewer && <AssetMarker isOpenInPopup={props.viewerState != null} viewer={viewer} isSelectionModeOn={selectionMode} segmentId={props.config?.segmentId} />}
        </div>
        <GeneralSnackbar config={generalSnackbarConfig} />
        <div id="measurementToolbox" style={{ position: 'absolute', bottom: '10px', left: '10px' }}>
            <Toolbox viewer={viewer} initialiseFlag={toolboxInitialiseFlag} toggleSelectionMode={toggleSelectionMode}></Toolbox>
        </div>
        <div id="credits" style={{ display: 'none', height: 0, width: 0 }}>div</div>
    </div>);
}

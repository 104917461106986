/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Distance } from '../Distance/Distance';
import { Area } from '../Area/Area';
import { Volume } from '../Volume/Volume';
import DistanceIcon from '../../../../images/line.png';
import AreaIcon from '../../../../images/polygon.png';
import SelectionIcon from '../../../../images/circleselect.png';
import VolumeIcon from '../../../../images/volume.png';
import styles from './ToolBox.module.css';
interface IProps {
  viewer: any;
  initialiseFlag: number;
  toggleSelectionMode : Function;
}
export function Toolbox(props: IProps) {
  const DISTANCE = 1;
  const AREA = 2;
  const SELECTION = 3;
  const VOLUME = 4;

  const [outputText, setOutputText] = useState<string>('');
  const [prompt, setPrompt] = useState<string>('');

  const [activeTool, setActiveTool] = useState<number | null>(null);

  const toggleToolModes = (mode: number) => {
    setOutputText('');

    if (mode === activeTool) {
      setActiveTool(null);
    } else {
      setActiveTool(mode);
    }
  };

  useEffect(() => {
    props.toggleSelectionMode(activeTool === SELECTION);
  }, [activeTool]);
  
    useEffect(() => {
        setActiveTool(null);
        setOutputText('');
    }, [props.initialiseFlag]);

  return (
    <div className="toolbox">
    <Box display="flex" flexDirection="column" height='100%'>
    <Box style={{textAlign: 'left'}} height='30px'>{prompt !== '' && <span className={styles.promptText}>{prompt}</span>}</Box>
    <Box style={{textAlign: 'left'}} display="flex" flexDirection="row">
      <Box>
          <button
            disabled={activeTool !== null && activeTool !== DISTANCE}
            onClick={() => {
              toggleToolModes(DISTANCE);
            }}
            style={{ border: activeTool === DISTANCE ? '2px solid red' : '1px solid #FCCC0D' }}
            className={styles.toolButton}
            title="Distance tool"
          >
            {<img src={DistanceIcon} className={styles.toolIcon} alt="Distance" aria-hidden="true" />}
          </button>
      </Box>
      <Box>
          <button
            // onClick={() => { setIsAreaModeOn(!isAreaModeOn); }}
            disabled={activeTool !== null && activeTool !== AREA}
            onClick={() => {
              toggleToolModes(AREA);
            }}
            style={{ border: activeTool === AREA ? '2px solid red' : '1px solid #FCCC0D' }}
            className={styles.toolButton}
            title="Area tool"
          >
            <img src={AreaIcon} className={styles.toolIcon} alt="Area" aria-hidden="true" />
          </button>
      </Box>
      <Box>
          <button
            disabled={activeTool !== null && activeTool !== VOLUME}
            onClick={() => {
              toggleToolModes(VOLUME);
            }}
            style={{ border: activeTool === VOLUME ? '2px solid red' : '1px solid #FCCC0D' }}
            className={styles.toolButton}
            title="Volume tool"
          >
            <img src={VolumeIcon} className={styles.toolIcon} alt="Volume" aria-hidden="true" />
          </button>
      </Box>
      <Box>
          <button
            // onClick={() => { setIsAreaModeOn(!isAreaModeOn); }}
            disabled={activeTool !== null && activeTool !== SELECTION}
            onClick={() => {
              toggleToolModes(SELECTION);
            }}
            style={{ border: activeTool === SELECTION ? '2px solid red' : '1px solid #FCCC0D' }}
            className={styles.toolButton}
            title="Point info"
          >
            <img src={SelectionIcon} className={styles.toolIcon} alt="Area" aria-hidden="true" />
          </button>
      </Box>
      <Box alignItems="center" display="flex">
        {activeTool !== null && outputText.length > 0 && <span className={styles.outputText}>{outputText}</span>}
      </Box>
      {props.viewer && <Distance viewer={props.viewer} isDistanceModeOn={activeTool === DISTANCE} initialiseFlag={props.initialiseFlag} />}
      {props.viewer && <Area viewer={props.viewer} isAreaModeOn={activeTool === AREA} displayArea={(t:string) => {if ( t.length > 0 ) setOutputText('Area = ' + t) }} />}
      {props.viewer && <Volume viewer={props.viewer} isVolumeModeOn={activeTool === VOLUME} displayVolume={(t:string) => {if ( t.length > 0 )  setOutputText('Volume = ' + t)}} prompt={(p:string) => setPrompt(p)} initialiseFlag={props.initialiseFlag} />}
    
    </Box>
    </Box>
    </div>
  );
}
